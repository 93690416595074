import React from "react";
import { closeModal } from "#redux/slices/globalUISlice";
import { useDispatch } from "react-redux";
import Button from "#helpers/Button";
import Modal from "./Modal";

const ConfirmationModal = ({ title, body, confirmText, cancelText, onConfirm, onCancel }) => {
  const dispatch = useDispatch();

  const handleCancel = () => {
    onCancel && onCancel();
    dispatch(closeModal());
  };

  const handleConfirm = () => {
    onConfirm && onConfirm();
    dispatch(closeModal());
  };

  return (
    <Modal isOpen classNames={{ root: "z-50" }}>
      <div className="flex flex-col gap-8 max-w-[500px] mt-3">
        <div>
          <p className="font-bold pb-2">{title}</p>
          {body && <p>{body}</p>}
        </div>
        <div className="flex gap-4 justify-end">
          <Button text={cancelText || "No"} color="bg-beige" size="medium" onClick={handleCancel} />
          <Button text={confirmText || "Yes"} color="bg-neonBlue" size="medium" onClick={handleConfirm} />
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;

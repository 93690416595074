import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchBook } from "./bookSlice.js";
import api from "#utils/api.js";

export const fetchScheduledBooks = createAsyncThunk("userBooks/fetchScheduledBooks", () =>
  api.get(`/user_books/scheduled_books`)
);

export const createUserBook = createAsyncThunk("userBooks/createUserBook", ({ userBookData, shelfIds }) =>
  api.post(`/user_books`, { user_book: userBookData, shelf_ids: shelfIds })
);

export const scheduleUserBook = createAsyncThunk("userBooks/scheduleUserBook", ({ userBookData }) =>
  api.post(`/user_books/schedule_book`, {
    book_id: userBookData.book_id,
    scheduled_for_year: userBookData.scheduled_for_year,
    scheduled_for_month: userBookData.scheduled_for_month,
  })
);

export const unscheduleUserBook = createAsyncThunk("userBooks/unscheduleUserBook", ({ userBook, userBookData }) =>
  api.put(`/user_books/${userBook.uuid}`, { user_book: userBookData })
);

export const updateUserBookStatus = createAsyncThunk("userBooks/updateUserBookStatus", ({ userBook, status }) =>
  api.post(`/user_books/${userBook.uuid}/update_status`, { status: status })
);

export const getReadingChallengeCard = createAsyncThunk("userBooks/getReadingChallengeCard", (userUuid) =>
  api.get(`/yearly_goal/goal_card?user_uuid=${userUuid}`)
);

export const updateYearGoal = createAsyncThunk("userBooks/updateYearGoal", ({ yearlyGoalId, goal }) =>
  api.put(`/yearly_goal/${yearlyGoalId}`, { goal: goal })
);

export const addToYearlyGoal = createAsyncThunk("userBooks/addToYearlyGoal", (bookId) =>
  api.post(`/yearly_goal/add_book`, { book_id: bookId })
);

export const removeFromYearlyGoal = createAsyncThunk("userBooks/removeFromYearlyGoal", (userBookId) =>
  api.post(`/yearly_goal/remove_book`, { user_book_id: userBookId })
);

export const updateReadingInstances = createAsyncThunk("userBooks/updateReadingInstances", ({ userBookUuid, data }) =>
  api.post(`/reading_instances/mass_update`, {
    user_book_uuid: userBookUuid,
    to_delete: data.to_delete,
    to_add: data.to_add,
  })
);

export const updateUserBook = createAsyncThunk("userBooks/updateUserBook", ({ userBookUuid, data }) =>
  api.put(`/user_books/${userBookUuid}`, {
    user_book: data.user_book,
    shelf_ids: data.shelf_ids,
    status: data.status,
  })
);

const initialState = {
  currentUserBook: null,
  userBooks: [],
  scheduledBooks: [],
};

export const userBookSlice = createSlice({
  name: "userBook",
  initialState,
  reducers: {
    setCurrentUserBook: (state, action) => {
      return {
        ...state,
        currentUserBook: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchScheduledBooks.fulfilled, (state, action) => {
      return { ...state, scheduledBooks: action.payload };
    });
    builder.addCase(updateUserBookStatus.fulfilled, (state, action) => {
      const updatedUserBook = action.payload;
      const updatedCurrentBook =
        state.currentUserBook?.id === updatedUserBook.id ? updatedUserBook : state.currentUserBook;

      return {
        ...state,
        currentUserBook: updatedCurrentBook,
      };
    });
    builder.addCase(updateUserBook.fulfilled, (state, action) => {
      const updatedUserBook = action.payload;
      const updatedCurrentBook =
        state.currentUserBook?.id === updatedUserBook.id ? updatedUserBook : state.currentUserBook;

      return {
        ...state,
        currentUserBook: updatedCurrentBook,
      };
    });
    // builder.addCase(createUserBook.fulfilled, (state, action) => {
    //   return {
    //     ...state,
    //     currentUserBook: action.payload,
    //   };
    // });
    builder.addCase(fetchBook.fulfilled, (state, action) => {
      return { ...state, currentUserBook: action.payload.user_book };
    });
    // builder.addCase(massUpdateShelfBook.fulfilled, (state, action) => {
    //   if (state.currentUserBook?.id === action.payload.id) {
    //     return { ...state, currentUserBook: action.payload };
    //   }
    //   return state;
    // });
    builder.addCase(scheduleUserBook.fulfilled, (state, action) => {
      const index = state.scheduledBooks.findIndex((book) => book.id === action.payload.id);
      if (index !== -1) {
        state.scheduledBooks[index] = action.payload;
      } else {
        state.scheduledBooks.push(action.payload);
      }
    });
    builder.addCase(unscheduleUserBook.fulfilled, (state, action) => {
      const index = state.scheduledBooks.findIndex((book) => book.id === action.payload.id);
      if (index !== -1) {
        state.scheduledBooks[index] = action.payload;
      }
    });
  },
});

export const { setCurrentUserBook } = userBookSlice.actions;

export default userBookSlice.reducer;
